/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Spinner, Image } from 'react-bootstrap';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import { useDispatch, useSelector } from "react-redux";
import { StartMainCommonLoader, getDeviceId, getSalesPersonLoginDetails, getActivateDeactivateStatus } from "../../redux/actions/adminActions";
import 'react-toastify/dist/ReactToastify.css';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import EM_LOGO from "../../assets/img/em_black_logo.svg";

const DeactivateAccount = () => {
    const [ email, setEmail ] = useState("");
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const dispatch = useDispatch();
    const { device_id, commonMainLoader, sales_person_login_details, sales_person_deactive_status } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);

    // generate unique id
    function uuidv4() { 
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    
    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorOtp, setErrorOtp ] = useState("");

    // on enter login
    const onEnterLogin = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SignInButton();
        };
    };

    const handleChange = (element, index) => {
        const value = element.value.replace(/[^0-9]/g, ''); // Allow only digits
        if (value) {
          const newOtp = [...otp];
          newOtp[index] = value;
          setOtp(newOtp);
    
          // Move to the next input field
          if (element.nextSibling) {
            element.nextSibling.focus();
          }
        } else {
        const newOtp = [...otp];
          newOtp[index] = "";
          setOtp(newOtp);
        }
    };
    
    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && !otp[index] && index !== 0) {
            // Move to the previous input field
            event.target.previousSibling.focus();
        }
    };
    
    // otp send function
    const SignInButton = async () => {
        if(email !== "") {
            if(EmailRegex.test(email) === true) {
                var DeviceID = uuidv4();
                dispatch(getDeviceId(DeviceID));
                dispatch(StartMainCommonLoader({
                    url: "salesperson_send_otp",
                    loader: true,
                }))
                let param = { 
                    "transmit": "single", 
                    "url": "salesperson_send_otp",
                    "request" :  { 
                        "email" : email, 
                    },
                    "DeviceId" : DeviceID,
                };
                wsSend_request(websocket, param);
            } else {
                CheckValid(email, {type: 'email', error: setErrorEmail });
            }
        } else {
            CheckValid(email, {type: 'email', error: setErrorEmail });
        };
    };

    // sign in function
    const SignInFrnchiseButton = () => {
        if(otp?.toString()?.replaceAll(",","") !== "") {
            dispatch(StartMainCommonLoader({
                url: "activate_deactivate_user",
                loader: true,
            }))
            let param = { 
                "transmit": "single", 
                "url": "activate_deactivate_user",
                "request" :  { 
                    "email": email,
                    "status": false,
                    "otp" : otp?.toString()?.replaceAll(",",""),
                },
                "DeviceId" : device_id,
            };
            wsSend_request(websocket, param);
        } else {
            CheckValid(otp?.toString()?.replaceAll(",",""), {type: 'otp', error: setErrorOtp });
        };
    };

    useEffect(() => {
        if(sales_person_login_details?.status === 200) {
            setEmail(sales_person_login_details?.data?.email);
        }
    }, [ sales_person_login_details ]);

    useEffect(() => {
        if(sales_person_deactive_status === 200) {
            Backbtn();
        }
    }, [ sales_person_deactive_status ])

    const Backbtn = () => {
        setErrorEmail("");
        setErrorOtp("");
        setOtp(['', '', '', '', '', '']);
        setEmail("");
        dispatch(getSalesPersonLoginDetails({}));
        dispatch(getActivateDeactivateStatus(""));
    };

    return(<div className="deactivate_account_wrapper" >
        <div className="main_form_Box">
            <div className="main_from_logo_header">
                <Image src={EM_LOGO} alt="logo" />
                <h4>Deactivate Account</h4>
            </div>
            <div className="loginMainblog Mainregisterproduct">                
                <div className="formloginset">
                    {sales_person_login_details?.status !== 200 ? (<Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ color: "#fff" }}>Email ID *</Form.Label>
                        <input type="email" className="d-none" />
                        <Form.Control type="email" value={email} onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail })} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => {onEnterLogin(e); EmptySpaceFieldValid(e)}} placeholder="Enter Email ID" autoComplete="new-email" disabled={(commonMainLoader?.url === "salesperson_send_otp" && commonMainLoader?.loader) || Object?.keys(sales_person_login_details === undefined ? {} : sales_person_login_details)?.length > 0} />
                        {errorEmail && (<div className="error_medotory" style={{ color: "#fff" }}>{errorEmail}</div>)}
                    </Form.Group>) :
                    (<>
                        <div className="verifyotpheader">
                            <h4>Are you sure you want to deactivate your account?</h4>
                            <p>Please enter the 6 digit code sent to {email}</p>
                        </div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <div className="otp_wrappper" >
                                {otp.map((digit, index) => (
                                    <Form.Control
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleChange(e.target, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </>)}

                    {Object?.keys(sales_person_login_details === undefined ? {} : sales_person_login_details)?.length === 0 ? (<Button onClick={SignInButton} className="registerbtn btn btn-primary"  disabled={(commonMainLoader?.url === "salesperson_send_otp" && commonMainLoader?.loader)}>
                        {(commonMainLoader?.url === "salesperson_send_otp" && commonMainLoader?.loader) ? (<Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>) : "Send OTP"}
                    </Button>) : (<React.Fragment>
                        <button className="back_btn sendOpt_btn" onClick={() => SignInButton()}>
                            {(commonMainLoader?.url === "salesperson_send_otp" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>)} 
                            <i className="bi bi-send"></i>Resend OTP
                        </button>
                        <Button onClick={SignInFrnchiseButton} className="registerbtn btn btn-primary"  disabled={(commonMainLoader?.url === "activate_deactivate_user" && commonMainLoader?.loader) || otp?.toString()?.replaceAll(",","")?.length < 6}>
                            {(commonMainLoader?.url === "activate_deactivate_user" && commonMainLoader?.loader) ? (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>) : "Confirm"}
                        </Button>
                        <button className="back_btn back_btn_transfernt" onClick={() => Backbtn()}>
                            <i className="bi bi-envelope-at-fill"></i>&nbsp; Change Email
                        </button>
                    </React.Fragment>)}
                </div>
            </div>
        </div>
    </div>)
}

export default DeactivateAccount;
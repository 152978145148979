/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { Image, Accordion } from 'react-bootstrap';
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import EM_LOGO from "../../assets/img/em_black_logo.svg";
import { Scrollbars } from 'react-custom-scrollbars-2';

const FAQ = () => {
    const { device_id, faqList } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "help_desk_faq_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : "",
                "sub_category_id": "",
                "order_by": "-updated_at"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, [ websocket ]);

    return(<div className="deactivate_account_wrapper" >
        <div className="main_form_Box">
            <div className="main_from_logo_header">
                <Image src={EM_LOGO} alt="logo" />
                <h4>FAQs</h4>
            </div>
            <div className="loginMainblog faq_wrapper">  
                <Scrollbars
                    style={{ height: "calc(100vh - 160px)" }}
                    renderView={props => <div {...props} className="view" />}
                >
                    <Accordion defaultActiveKey="0">
                        <div className="faq_logs_wrapper">
                            {faqList?.map((elm, index) => {
                                return(<Accordion.Item key={index} eventKey={index}>
                                    <Accordion.Header>{index + 1}. {elm?.question}</Accordion.Header>
                                    <Accordion.Body>{elm?.answer}</Accordion.Body>
                                </Accordion.Item>)
                            })}
                        </div>
                    </Accordion>
                </Scrollbars>              
            </div>
        </div>
    </div>)
}

export default FAQ;
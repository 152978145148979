/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

const initailData = {
    is_login: "no",
    user_id: "",
    device_id: "",
    selfInfo: {},
    accessToken: "",
    MainNavUrl: "",
    ClearFormSet: {
        url: "",
        action: false
    },
    ImageStored: {},
    brandList: {
        list: [],
        pagination: {}
    },
    boxList: {
        list: [],
        pagination: {}
    },
    AllboxList: [],
    categoryList: {
        list: [],
        brands: [],
        pagination: {}
    },
    subcategoryList: {
        list: [],
        category: [],
        pagination: {}
    },
    productList: {
        list: [],
        sub_category: [],
        pagination: {}
    },
    stateList: {
        list: [],
        pagination: {}
    },
    cityList: {
        list: [],
        state: [],
        pagination: {}
    },
    pincodeList: {
        list: [],
        district: [],
        pagination: {}
    },
    regionMasterList: {
        list: [],
        pagination: {}
    },
    userList: {
        list: [],
        pagination: {}
    },
    userlistAll: [],
    wareHouseList: {
        list: [],
        pin_code: [],
        pagination: {}
    },
    storedDetailsReportList: {
        list: [],
        pagination: {}
    },
    accessoriesReportList: {
        list: [],
        pagination: {}
    },
    storedLevelReportList: {
        list: [],
        grand: {},
        pagination: {}
    },
    FranchiseTypeList: {
        list: [],
        pagination: {}
    },
    FranchiseUserTypeList: {
        list: [],
        pin: [],
        district: [],
        state: [],
        pagination: {}
    },
    OrderReportList: {
        list: [],
        pin: [],
        district: [],
        state: [],
        pagination: {}
    },
    AllDealerFranchiseTypeListAll: {
        list: [],
        pin: []
    },
    wareHouselistall: [],
    franchiseTypeListAll: [],
    brandlistall: [],
    citylistall: [],
    Regionlistall: [],
    categorylistall: [],
    productlistall: [],
    subCategorylistall: [],
    statelistall: [],
    ListActiveStatus: false,
    magic_code: "",
    ClearCachesz: "",
    searchPincodeList: {
        pin: {},
        city: {},
        state: {},
    },
    ErrorMessageFromReddux: {
        API: "",
        Error: "",
    },
    commonLoader: false,
    ProductGroupList: {
        list: [],
        pagination: {}
    },
    AllProductGroupListAll: [],
    OrderReportDetails: {},
    OrderPOApprovalsList: {
        list: [],
        franchise: [],
        franchise_type: [],
        pagination: {}
    },
    OrderWareHouseApprovalsList: {
        list: [],
        franchise: [],
        franchise_type: [],
        pagination: {}
    },
    PendingForAccountsList: {
        list: [],
        franchise: [],
        franchise_type: [],
        pagination: {}
    },
    PendingForStoresList: {
        list: [],
        franchise: [],
        franchise_type: [],
        pagination: {}
    },
    DispatchLists: {
        list: [],
        warehouses: [],
        district: [],
        pin_code: [],
        state: [],
        pagination: {},
    },
    AllDispacchList: [],
    OrderReportOptions: {
        data: [],
        pagination: {},
        product: [],
        warehouse: []
    },
    CourierList: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    OptionCourierList: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    PendingDispatchList: {
        franchise: [],
        franchise_type: [],
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    PendingDeliveryList: {
        franchise: [],
        franchise_type: [],
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    DeliveredList: {
        franchise: [],
        franchise_type: [],
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    RejectedOrderList: {
        franchise: [],
        franchise_type: [],
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    CancelledOrderList: {
        franchise: [],
        franchise_type: [],
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    InvoiceList: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    EInvoiceSuccess: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    EInvoiceFailure: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    ModalInvoice: null,
    OptionList: {},
    purchaseList: {
        list: [],
        pagination: {}
    },
    commonMainLoader: {
        url: "",
        loader: false
    },
    getFrameList: {},
    dealers_login_details: {},
    sales_person_login_details: {},
    sales_person_deactive_status: "",
    startCommonLoader: false,
    getrefurbished_brand_list: [],
    getrefurbished_point_list: [],
    customerdetails: {},
    bikePurchaseData: {},
    typesofbicycle: {},
    bikeconditionFilter: [],
    refurbishedResponse: {},
    bikeCondtionsSteps: 0,
    inspection_videos: [],
    verifyFiedModal: true,
    dealerCustomerStatus: {
        dealerExist: false,
        customerExist: false,
    },
    faqList: [],
    cacheClear_:"cacheClear"
};

export const adminReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.IS_LOGIN:
            return {
                ...state,
                is_login: action.payload,
            };
        case ActionTypes.SET_STORE_COURIERS_LIST:
            return {
                ...state,
                CourierList: action.payload,
            };
        case ActionTypes.SET_STORE_INVOICE_LIST:
            return {
                ...state,
                InvoiceList: action.payload,
            };
        case ActionTypes.SET_STORE_EINVOICE_SUCCESS_LIST:
            return {
                ...state,
                EInvoiceSuccess: action.payload,
            };
        case ActionTypes.SET_STORE_EINVOICE_FAILURE_LIST:
            return {
                ...state,
                EInvoiceFailure: action.payload,
            };
        case ActionTypes.SET_STORE_CANCELLED_ORDER_LIST:
            return {
                ...state,
                CancelledOrderList: action.payload,
            };
        case ActionTypes.SET_STORE_OPTION_COURIERS_LIST:
            return {
                ...state,
                OptionCourierList: action.payload,
            };
        case ActionTypes.GET_USER_ID:
            return {
                ...state,
                user_id: action.payload,
            };
        case ActionTypes.GET_DEVICE_ID:
            return {
                ...state,
                device_id: action.payload,
            };
        case ActionTypes.SET_MAGIC_CODE:
            return {
                ...state,
                magic_code: action?.payload
            };
        case ActionTypes.SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.GET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ActionTypes.MAIN_NAVIGATION:
            return {
                ...state,
                MainNavUrl: action.payload,
            };
        case ActionTypes.CLEAR_FORM_SET_FUCT:
            return {
                ...state,
                ClearFormSet: {
                    url: action?.payload?.url,
                    action: action?.payload?.action
                }
            };
        case ActionTypes.SET_STORE_OPTIONS_LIST:
            const oldOption = state?.OptionList || {};
            oldOption[action?.payload?.id] = action.payload.data;
            return {
                ...state,
                OptionList: oldOption
            };
        case ActionTypes.SET_STORE_OPTIONS_LIST_CLEAR:
            return {
                ...state,
                OptionList: {},
            };
        case ActionTypes.GET_BRAND_LIST:
            return {
                ...state,
                brandList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_NEW_BRAND_LIST:
            const OldBrandlist = [...state.brandList.list];
            OldBrandlist.unshift(action.payload);
            return {
                ...state,
                brandList: {
                    ...state.brandList,
                    list: OldBrandlist,
                },
            }
        case ActionTypes.EDIT_BRAND_LIST:
            const OldBrandlistx = [...state.brandList.list];
            const UpdateBrandList = OldBrandlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        brand_image: action.payload.brand_image,
                        brand_name: action.payload.brand_name,
                        is_active: action.payload.is_active,
                    };
                } else
                    return elm;
            });
            return {
                ...state,
                brandList: {
                    ...state.brandList,
                    list: UpdateBrandList,
                }
            };
        case ActionTypes.IMAGE_STORED_REDUX:
            return {
                ...state,
                ImageStored: {
                    ...state?.ImageStored,
                    [action?.payload?.fileresponse?.id]: action?.payload?.Authfileresponse?.authImage
                },
            };
        case ActionTypes.GET_CATEGORY_LIST:
            return {
                ...state,
                categoryList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    brands: Object.keys(action.payload).length === 0 ? [] : action.payload.brands,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_CATEGORY_LIST:
            const OldCategorylist = [...state.categoryList.list];
            OldCategorylist.unshift(action.payload);
            return {
                ...state,
                categoryList: {
                    ...state.categoryList,
                    list: OldCategorylist,
                },
            };
        case ActionTypes.UPDATE_CATEGORY_LIST:
            const OldCategorylistx = [...state.categoryList.list];
            const UpdateCategoryList = OldCategorylistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        category_name: action.payload.category_name,
                        category_image: action.payload.category_image,
                        brand_id: action.payload.brand_id,
                        is_active: action.payload.is_active
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                categoryList: {
                    ...state.categoryList,
                    list: UpdateCategoryList,
                },
            };
        case ActionTypes.GET_ALL_BRANDS_LIST:
            return {
                ...state,
                brandlistall: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.data,
            };
        case ActionTypes.GET_ALL_CATEGORY_LIST:
            return {
                ...state,
                categorylistall: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.data,
            };
        case ActionTypes.GET_ALL_SUB_CATEGORY_LIST:
            return {
                ...state,
                subCategorylistall: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.data,
            };
        case ActionTypes.ACTIVE_LIST_ITEM_STATUS:
            return {
                ...state,
                ListActiveStatus: action.payload
            };
        case ActionTypes.GET_SUB_CATEGORY_LIST:
            return {
                ...state,
                subcategoryList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    category: Object.keys(action.payload).length === 0 ? [] : action.payload.category,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_SUB_CATEGORY_LIST:
            const OldSubCategorylist = [...state.subcategoryList.list];
            OldSubCategorylist.unshift(action.payload);
            return {
                ...state,
                subcategoryList: {
                    ...state.subcategoryList,
                    list: OldSubCategorylist,
                },
            };
        case ActionTypes.UPDATE_SUB_CATEGORY_LIST:
            const OldSubCategorylistx = [...state.subcategoryList.list];
            const UpdateSubCategoryList = OldSubCategorylistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        sub_category_name: action.payload.sub_category_name,
                        category_name: action.payload.category_name,
                        is_active: action.payload.is_active
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                subcategoryList: {
                    ...state.subcategoryList,
                    list: UpdateSubCategoryList,
                },
            };
        case ActionTypes.GET_PRODUCT_LIST:
            return {
                ...state,
                productList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    sub_category: Object.keys(action.payload).length === 0 ? [] : action.payload.sub_category,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_PRODUCT_LIST:
            const OldProductlist = [...state.productList.list];
            OldProductlist.unshift(action.payload);
            return {
                ...state,
                productList: {
                    ...state.productList,
                    list: OldProductlist,
                },
            };
        case ActionTypes.UPDATE_PRODUCT_LIST:
            const OldProductlistx = [...state.productList.list];
            const UpdateProductList = OldProductlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        sku: action.payload.sku,
                        product_name: action.payload.product_name,
                        display_name: action.payload.display_name,
                        short_description: action.payload.short_description,
                        product_image: action.payload.product_image,
                        product_group_id: action.payload.product_group_id,
                        hsn_code: action.payload.hsn_code,
                        product_type_id: action.payload.product_type_id,
                        is_return: action.payload.is_return,
                        return_remark: action.payload.return_remark,
                        mrp: action.payload.mrp,
                        franchise_rate: action.payload.franchise_rate,
                        retail_price: action.payload.retail_price,
                        discount_amount: action.payload.discount_amount,
                        ecom_price: action.payload.ecom_price,
                        fs_code: action.payload.fs_code,
                        product_color: action.payload.product_color,
                        igst_rate: action.payload.igst_rate,
                        cgst_rate: action.payload.cgst_rate,
                        sgst_rate: action.payload.sgst_rate,
                        is_active: action.payload.is_active,
                        sub_category_id: action.payload.sub_category_id
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                productList: {
                    ...state.productList,
                    list: UpdateProductList,
                },
            };
        case ActionTypes.GET_STATE_LIST:
            return {
                ...state,
                stateList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_STATE_LIST:
            const OldStatelist = [...state.stateList.list];
            OldStatelist.unshift(action.payload);
            return {
                ...state,
                stateList: {
                    ...state.stateList,
                    list: OldStatelist,
                },
            };
        case ActionTypes.GET_ALL_STATE_LIST:
            return {
                ...state,
                statelistall: Object.keys(action.payload).length === 0 ? [] : action.payload,
            }
        case ActionTypes.UPDATE_STATE_LIST:
            const OldStatelistx = [...state.stateList.list];
            const UpdateStatelist = OldStatelistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        state_name: action.payload?.state_name,
                        state_code: action.payload?.state_code,
                        gst_code: action.payload?.gst_code,
                        region_id: action.payload?.region_id,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                stateList: {
                    ...state.stateList,
                    list: UpdateStatelist,
                },
            };

        case ActionTypes.GET_CITY_LIST:
            return {
                ...state,
                cityList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.GET_PIN_CODE_LIST:
            return {
                ...state,
                pincodeList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.GET_ALL_PRODUCT_LIST:
            return {
                ...state,
                productlistall: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.GET_ALL_CITY_LIST:
            return {
                ...state,
                citylistall: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.ADD_CITY_LIST:
            const OldCitylist = [...state.cityList.list];
            OldCitylist.unshift(action.payload);
            return {
                ...state,
                cityList: {
                    ...state.cityList,
                    list: OldCitylist,
                },
            };
        case ActionTypes.UPDATE_CITY_LIST:
            const OldCitylistx = [...state.cityList.list];
            const UpdateCityList = OldCitylistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        district_name: action.payload?.district_name,
                        state_id: action.payload?.state_id,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                cityList: {
                    ...state.cityList,
                    list: UpdateCityList,
                },
            };

        case ActionTypes.GET_REGION_MASTER_LIST:
            return {
                ...state,
                regionMasterList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_REGION_MASTER_LIST:
            const OldRegionMasterlist = [...state.regionMasterList.list];
            OldRegionMasterlist.unshift(action.payload);
            return {
                ...state,
                regionMasterList: {
                    ...state.regionMasterList,
                    list: OldRegionMasterlist,
                },
            };
        case ActionTypes.UPDATE_REGION_MASTER_LIST:
            const OldRegionMasterlistx = [...state.regionMasterList.list];
            const UpdateRegionMasterlist = OldRegionMasterlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        region_name: action.payload.region_name,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                regionMasterList: {
                    ...state.regionMasterList,
                    list: UpdateRegionMasterlist,
                },
            };
        case ActionTypes.GET_USER_LIST:
            return {
                ...state,
                userList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_USER_LIST:
            const OldUserlist = [...state.userList.list];
            OldUserlist.unshift(action.payload);
            return {
                ...state,
                userList: {
                    ...state.userList,
                    list: OldUserlist,
                },
            };
        case ActionTypes.UPDATE_USER_LIST:
            const OldUserlistx = [...state.userList.list];
            const UpdateUserlist = OldUserlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        full_name: action.payload?.full_name,
                        email: action.payload?.email,
                        mobile: action.payload?.mobile,
                        address: action.payload?.address,
                        user_type: action.payload?.user_type,
                        permissions: action.payload?.permissions,
                        related_id: action.payload?.related_id,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                userList: {
                    ...state.userList,
                    list: UpdateUserlist,
                },
            };
        case ActionTypes.GET_ALL_USER_LIST:
            return {
                ...state,
                userlistAll: Object.keys(action.payload).length === 0 ? [] : action?.payload,
            };
        case ActionTypes.GET_ALL_REGION_LIST:
            return {
                ...state,
                Regionlistall: Object.keys(action.payload).length === 0 ? [] : action?.payload,
            };
        case ActionTypes.GET_WAREHOUSE_LIST:
            return {
                ...state,
                wareHouseList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pin_code: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_WAREHOUSE_LIST:
            const OldWareHouselist = [...state.wareHouseList.list];
            OldWareHouselist.unshift(action.payload);
            return {
                ...state,
                wareHouseList: {
                    ...state.wareHouseList,
                    list: OldWareHouselist,
                },
            };
        case ActionTypes.UPDATE_WAREHOUSE_LIST:
            const OldwareHouselistx = [...state.wareHouseList.list];
            const UpdateWarehouselist = OldwareHouselistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        warehouse_name: action.payload?.warehouse_name,
                        display_name: action.payload?.display_name,
                        pin_code_id: action.payload?.pin_code_id,
                        address: action.payload?.address,
                        poc_name: action.payload?.poc_name,
                        poc_number: action.payload?.poc_number,
                        pan_no: action.payload?.pan_no,
                        pan_image: action.payload?.pan_image,
                        gstin_no: action.payload?.gstin_no,
                        gstin_image: action.payload?.gstin_image,
                        cin_no: action.payload?.cin_no,
                        region_id: action.payload?.region_id,
                        warehouse_code: action.payload?.warehouse_code,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                wareHouseList: {
                    ...state.wareHouseList,
                    list: UpdateWarehouselist,
                },
            };
        case ActionTypes.GET_ALL_WAREHOUSE_LIST:
            return {
                ...state,
                wareHouselistall: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.PINCODES_SEARCH_LIST:
            return {
                ...state,
                searchPincodeList: {
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin,
                    city: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                },
            };
        case ActionTypes.COMMON_LOADER:
            return {
                ...state,
                commonLoader: action.payload,
            }
        case ActionTypes.ERROR_MESSAGE_FROM_REDUX:
            return {
                ...state,
                ErrorMessageFromReddux: {
                    API: action.payload.API,
                    Error: action.payload.Error,
                },
            };
        case ActionTypes.STORED_DETAIL_REPORT_LIST:
            return {
                ...state,
                storedDetailsReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.SET_STORE_ACCESSORIES_REPORT:
            return {
                ...state,
                accessoriesReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.SET_STORE_PENDING_DISPATCH_LIST:
            return {
                ...state,
                PendingDispatchList: action.payload
            };
        case ActionTypes.SET_STORE_PENDING_DELIVERY_LIST:
            return {
                ...state,
                PendingDeliveryList: action.payload
            };
        case ActionTypes.SET_STORE_DELIVERY_LIST:
            return {
                ...state,
                DeliveredList: action.payload
            };
        case ActionTypes.SET_STORE_REJECTED_ORDER_LIST:
            return {
                ...state,
                RejectedOrderList: action.payload
            };
        case ActionTypes.STORED_LEVEL_REPORT_LIST:
            return {
                ...state,
                storedLevelReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    grand: Object.keys(action.payload).length === 0 ? [] : action.payload.grand,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.FRANCHISE_TYPE_LIST:
            return {
                ...state,
                FranchiseTypeList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_FRANCHISE_TYPE_LIST:
            const OldFranchiseTypelist = [...state.FranchiseTypeList.list];
            OldFranchiseTypelist.unshift(action.payload);
            return {
                ...state,
                FranchiseTypeList: {
                    ...state.FranchiseTypeList,
                    list: OldFranchiseTypelist,
                },
            };
        case ActionTypes.UPDATE_FRANCHISE_TYPE_LIST:
            const OldFranchiseTypelistx = [...state.FranchiseTypeList.list];
            const UpdateOldFranchiseTypelist = OldFranchiseTypelistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        franchise_type_name: action.payload?.franchise_type_name,
                        franchise_type_credit: action.payload?.franchise_type_credit,
                        is_active: action.payload?.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                FranchiseTypeList: {
                    ...state.FranchiseTypeList,
                    list: UpdateOldFranchiseTypelist,
                },
            };
        case ActionTypes.GET_ALL_FRANCHISE_TYPE_LIST_LIST:
            return {
                ...state,
                franchiseTypeListAll: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.GET_FRANCHISE_USER_TYPE_LIST:
            return {
                ...state,
                FranchiseUserTypeList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                },
            };
        case ActionTypes.GET_ALL_DEALER_FRANCHISE_USER_TYPE_LIST:
            return {
                ...state,
                AllDealerFranchiseTypeListAll: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code
                },
            };
        case ActionTypes.ADD_FRANCHISE_USER_TYPE_LIST:
            const OldFranchiseUserTypelist = [...state.FranchiseUserTypeList.list];
            OldFranchiseUserTypelist.unshift(action.payload);
            return {
                ...state,
                FranchiseUserTypeList: {
                    ...state.FranchiseUserTypeList,
                    list: OldFranchiseUserTypelist,
                },
            };
        case ActionTypes.UPDATE_FRANCHISE_USER_TYPE_LIST:
            const OldFranchiseUserTypeListx = [...state.FranchiseUserTypeList.list];
            const UpdateFranchiseUserTypeListx = OldFranchiseUserTypeListx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        "franchise_type": action.payload?.franchise_type,
                        "parent_id": action.payload?.parent_id,
                        "franchise_type_id": action.payload?.franchise_type_id,
                        "customer_name": action.payload?.customer_name,
                        "poc_name": action.payload?.poc_name,
                        "mobile": action.payload?.mobile,
                        "secondary_contact": action.payload?.secondary_contact,
                        "email": action.payload?.email,
                        "pin_code_id": action.payload?.pin_code_id,
                        "address": action.payload?.address,
                        "gst_no": action.payload?.gst_no,
                        "gst_image": action.payload?.gst_image,
                        "credit_limit": action.payload?.credit_limit,
                        "advance_amount": action.payload?.advance_amount,
                        "is_active": action.payload?.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                FranchiseUserTypeList: {
                    ...state.FranchiseUserTypeList,
                    list: UpdateFranchiseUserTypeListx,
                },
            };
        case ActionTypes.GET_PRODUCT_GROUP_LIST:
            return {
                ...state,
                ProductGroupList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_PRODUCT_GROUP_LIST:
            const OldProductGrouplist = [...state.ProductGroupList.list];
            OldProductGrouplist.unshift(action.payload);
            return {
                ...state,
                ProductGroupList: {
                    ...state.ProductGroupList,
                    list: OldProductGrouplist,
                },
            };
        case ActionTypes.UPDATE_PRODUCT_GROUP_LIST:
            const OldProductGrouplistX = [...state.ProductGroupList.list];
            const UpdateOldProductGroupList = OldProductGrouplistX.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        product_group_name: action.payload?.product_group_name,
                        is_active: action.payload?.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                ProductGroupList: {
                    ...state.ProductGroupList,
                    list: UpdateOldProductGroupList,
                },
            };
        case ActionTypes.GET_ALL_PRODUCT_GROUP_LIST:
            return {
                ...state,
                AllProductGroupListAll: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.FRANCHISE_ORDER_REPORT_LIST:
            return {
                ...state,
                OrderReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                }
            }
        case ActionTypes.ORDER_PO_APPROVALS_ORDERS:
            return {
                ...state,
                OrderPOApprovalsList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.ORDER_WAREHOUSE_APPROVALS_ORDERS:
            return {
                ...state,
                OrderWareHouseApprovalsList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.PENDING_FOR_ACCOUNT_ORDERS_LIST:
            return {
                ...state,
                PendingForAccountsList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.PENDING_FOR_STORES_ORDERS_LIST:
            return {
                ...state,
                PendingForStoresList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.DISPATCH_LIST:
            return {
                ...state,
                DispatchLists: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    warehouses: Object.keys(action.payload).length === 0 ? [] : action.payload.warehouses,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    pin_code: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.ADD_DISPATCH_LIST:
            const OldDispatchlist = [...state.DispatchLists.list];
            OldDispatchlist.unshift(action.payload);
            return {
                ...state,
                DispatchLists: {
                    ...state.DispatchLists,
                    list: OldDispatchlist,
                },
            };
        case ActionTypes.UPDATE_DISPATCH_LIST:
            const OldDispatchlistX = [...state.DispatchLists.list];
            const UpdateOldOldDispatchlistX = OldDispatchlistX.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        dispatch_name: action.payload?.dispatch_name,
                        mobile: action.payload?.mobile,
                        email: action.payload?.email,
                        pin_code_id: action.payload?.pin_code_id,
                        gst_no: action.payload?.gst_no,
                        address: action.payload?.address,
                        warehouse_id: action.payload?.warehouse_id,
                        is_active: action.payload?.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                DispatchLists: {
                    ...state.DispatchLists,
                    list: UpdateOldOldDispatchlistX,
                },
            };
        case ActionTypes.GET_ALL_DISPATCH_LIST:
            return {
                ...state,
                AllDispacchList: action?.payload,
            };
        case ActionTypes.ORDER_DETAILS_REPORT:
            return {
                ...state,
                OrderReportDetails: action?.payload
            }
        case ActionTypes.SET_STORE_ACCESSORIES_LIST:
            return {
                ...state,
                OrderReportOptions: action?.payload
            }
        case ActionTypes.SET_STORE_MODAL_INVOICE_DETAILS:
            return {
                ...state,
                ModalInvoice: action?.payload
            }
        case ActionTypes.GET_STOCK_INWARD_LIST:
            return {
                ...state,
                StockInwardList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.GET_BOX_LIST:
            return {
                ...state,
                boxList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_BOX_LIST:
            const OldBoxlist = [...state.boxList.list];
            OldBoxlist.unshift(action.payload);
            return {
                ...state,
                boxList: {
                    ...state.boxList,
                    list: OldBoxlist,
                },
            };
        case ActionTypes.UPDATE_BOX_LIST:
            const OldBoxlistx = [...state.boxList.list];
            const UpdateBoxlist = OldBoxlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        box_name: action.payload?.box_name,
                        box_height: action.payload?.box_height,
                        box_width: action.payload?.box_width,
                        box_length: action.payload?.box_length,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                boxList: {
                    ...state.boxList,
                    list: UpdateBoxlist,
                },
            };
        case ActionTypes.GET_ALL_BOX_LIST:
            return {
                ...state,
                AllboxList: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.ADD_PURCHASE_LIST:
            const OldPurchaselist = Array.isArray(state.purchaseList?.list) ? [...state.purchaseList?.list] : [];;
            OldPurchaselist.unshift(action.payload);
            return {
                ...state,
                purchaseList: {
                    ...state.purchaseList,
                    list: OldPurchaselist,
                },
            };
        case ActionTypes.COMMON_MAIN_LOADER:
            return {
                ...state,
                commonMainLoader: {
                    url: action.payload.url,
                    loader: action.payload.loader,
                }
            }
        case ActionTypes.GET_BRAND_REFURBISHED_LIST:
            return {
                ...state,
                getrefurbished_brand_list: action.payload,
            }
        case ActionTypes.GET_POINT_REFURBISHED_LIST:
            return {
                ...state,
                getrefurbished_point_list: action.payload,
            }
        case ActionTypes.GET_CUSTOMER_DETAILS:
            return {
                ...state,
                customerdetails: action.payload,
            }
        case ActionTypes.GET_BIKE_PURCHASE_DATA:
            return {
                ...state,
                bikePurchaseData: action.payload,
            }
        case ActionTypes.GET_TYPES_OF_BICYCLES:
            return {
                ...state,
                typesofbicycle: action.payload,
            }
        case ActionTypes.GET_BIKE_CONDITIONS_FILTER:
            return {
                ...state,
                bikeconditionFilter: action.payload,
            }
        case ActionTypes.GET_REFURBISHED_RESPONSE:
            return {
                ...state,
                refurbishedResponse: action.payload,
            }
        case ActionTypes.GET_BIKE_CONDITIONS_STEPS:
            return {
                ...state,
                bikeCondtionsSteps: action.payload,
            }
        case ActionTypes.GET_ALL_FRAME_LIST:
            return {
                ...state,
                getFrameList : Object.keys(action.payload).length === 0 ? {} : action.payload,
            };
        case ActionTypes.GET_INSECTION_VIDEOS:
            return {
                ...state,
                inspection_videos: action.payload,
            };
        case ActionTypes.GET_DEALERS_LOGIN_DETAILS:
            return {
                ...state,
                dealers_login_details: action.payload,
            };
        case ActionTypes.GET_SALES_PERSON_LOGIN_DETAILS:
            return {
                ...state,
                sales_person_login_details: action.payload,
            };
        case ActionTypes.GET_SALES_PERSON_STATUS:
            return {
                ...state,
                sales_person_deactive_status: action.payload,
            };
        case ActionTypes.GET_MODAL_VERIFY_OPEN_CLOSE:
            return {
                ...state,
                verifyFiedModal: action.payload,
            };
        case ActionTypes.GET_DEALER_CUSTOMER_STATUS:
            return {
                ...state,
                dealerCustomerStatus: {
                    ...state.dealerCustomerStatus,
                    dealerExist: action?.payload?.dealerExist,
                    customerExist: action?.payload?.customerExist,
                },
            };
        case ActionTypes.GET_ALL_FAQ_LIST:
            return {
                ...state,
                faqList: action.payload,
            }
        case ActionTypes.ADMIN_LOGOUT:
            return {
                ...initailData,
            };
        default:
            return state;
    }
};